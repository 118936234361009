/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import {useStaticQuery, graphql, PageProps} from "gatsby"
import HTMLReactParser from "html-react-parser"

import {macroReplacer} from "../transformers"

import {Page} from "../../types"

import Layout from "../components/layout"
import Sidebar from "../components/sidebar"
import BannerGroup from "../components/banner-group"

const DocumentArchivePageTemplate = (page: PageProps): JSX.Element => {
  const data = page.pageContext as Page
  const landingPages = useStaticQuery(graphql`
    query documentsLandingPageQuery {
      pages: allDatoCmsPage(
        filter: {pageType: {key: {eq: "documents-landing-page"}}}
      ) {
        edges {
          node {
            header {
              bannerTitle
              bannerText
              bannerImage {
                url
              }
              bannerCta {
                slug
              }
              bannerCtaText
            }
          }
        }
      }
    }
  `).pages.edges

  const headerContent = [
    {
      bannerTitle: landingPages[0].node.header[0].bannerTitle,
      bannerText: landingPages[0].node.header[0].bannerText,
      bannerImage: {
        url: data.visual?.url || landingPages[0].node.header[0].bannerImage.url,
      },
    },
  ]

  return (
    <Layout title={data.title} currentPage={data}>
      <BannerGroup banners={headerContent} />
      <Sidebar
        currentPage={data}
        landingPageType="documents-landing-page"
        alphabetised
      >
        <h3>{data.title}</h3>
        <div css={DocumentArchivePageStyles}>
          {HTMLReactParser(data.content, {
            replace: object => macroReplacer(data, object),
          })}
        </div>
      </Sidebar>
    </Layout>
  )
}

export default DocumentArchivePageTemplate

const DocumentArchivePageStyles = css`
  max-width: 80rem;

  h1,
  h2,
  h3,
  h4 {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .document-link {
    padding-left: 0;

    li {
      margin-bottom: 2rem;
      list-style-type: none;
    }

    .metadata {
      display: block !important;
    }
  }
`
